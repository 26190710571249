import Head from "next/head";
import Script from "next/script";
import Worpel from "../games/Worpel/Worpel";

const REACT_APP_NAME = "Worpel | AFL Player Daily Guessing Game";
const REACT_APP_DESC = "Can you guess who today's mystery AFL player is?";
const REACT_APP_URL = "https://playworpel.com/";
const REACT_APP_IMG_URL = `${REACT_APP_URL}img/worpel/worpel-social.jpeg`;

export default function SSG() {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/icons/logo.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/icons/apple-icon-180x180.png" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap"
          rel="stylesheet"
        />

        {"<!-- Primary Meta Tags -->"}
        <title>{REACT_APP_NAME}</title>
        <meta name="title" content={REACT_APP_NAME} />
        <meta name="description" content={REACT_APP_DESC} />
        <meta name="theme-color" content="#005979" />
        <link rel="manifest" href="/manifest.json" />

        {"<!-- Open Graph / Facebook -->"}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={REACT_APP_URL} />
        <meta property="og:title" content={REACT_APP_NAME} />
        <meta property="og:description" content={REACT_APP_DESC} />
        <meta property="og:image" content={REACT_APP_IMG_URL} />

        {"<!-- Twitter -->"}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={REACT_APP_URL} />
        <meta property="twitter:title" content={REACT_APP_NAME} />
        <meta property="twitter:description" content={REACT_APP_DESC} />
        <meta property="twitter:image" content={REACT_APP_IMG_URL} />
      </Head>
      <Worpel />

      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-PCP49YP234"
        strategy="afterInteractive"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());

            gtag("config", "G-PCP49YP234");
        `,
        }}
      ></Script>
      <Script
        id="google-adsense"
        async="true"
        strategy="beforeInteractive"
        onError={(e) => {
          console.error("Adsense script failed to load", e);
        }}
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4746633404428334"
      />
    </>
  );
}
